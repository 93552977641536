import actionTypes from './actionTypes';

export const openCustomViewModal = (data) => (
  {
    type: actionTypes.OPEN_CUSTOM_VIEW_MODAL,
    data: {'isOpenCustomViewModal': true, ...data}
  }
);

export const closeCustomViewModal = (data) => (
  {
    type: actionTypes.CLOSE_CUSTOM_VIEW_MODAL,
    data: {'isOpenCustomViewModal': false, ...data}
  }
);

export const saveCustomView = data => (
  {
    type: actionTypes.SAVE_CUSTOM_VIEW,
    data
  }
);

export const editCustomView = data => (
  {
    type: actionTypes.EDIT_CUSTOM_VIEW,
    data
  }
);

export const deleteCustomView = data => (
  {
    type: actionTypes.DELETE_CUSTOM_VIEW,
    data
  }
);

export const sortChange = (data) => (
  {
    type: actionTypes.CHANGE_COLUMN_SORT,
    data: data.activeView,
    reducerHelper: data.params
  }
);
export const columnMoved = data => (
  {
    type: actionTypes.CHANGE_COLUMN_ORDER,
    data
  }
);
export const columnRemoved = data => (
  {
    type: actionTypes.REMOVE_COLUMN,
    data
  }
);

export const groupChange = data => (
  {
    type: actionTypes.CHANGE_GROUP,
    data: {
      groupBy: data.groupBy,
      activeView: data.activeView
    }
  }
);

export const columnWidthChanged = data => (
  {
    type: actionTypes.CHANGE_COLUMN_WIDTH,
    data
  }
);

export const viewChange = data => (
  {
    type: actionTypes.CHANGE_VIEW,
    data
  }
);

export const applyFilter = data => ({
  type: actionTypes.APPLY_FILTER,
  data: {'filterBy': data.filters, 'filterModel': data.filterModel}
});

export const toggleFilterVisibility = data => ({
  type: actionTypes.TOGGLE_FILTER_VISIBILITY,
  data
});

export const addPortfolioLoadedData = data => (
  {
    type: actionTypes.ADD_PORTFOLIO_LOADED_DATA,
    data: {
      columnsMetadata: data.columnsMetadata,
      groupColumns: data.groupColumns,
      loading: data.loading,
      views: data.views,
      groupBy: data.groupBy,
      filterBy: data.filterBy,
      isOpenCustomViewModal: false
    }
  }
);

export const addPortfolioData = data => (
  {
    type: actionTypes.ADD_PORTFOLIO_DATA,
    data: {
      loading: data.loading,
      portfolioData: data.portfolioData,
      totals: data.totals
    }
  }
);

export const portfolioGroupChangedData = data => (
  {
    type: actionTypes.ADD_PORTFOLIO_GROUP_CHANGED_DATA,
    data: {
      loading: data.loading,
      views: data.views,
      portfolioData: data.portfolioData,
      totals: data.totals
    }
  }
);

export const portfolioDataLoading = data => (
  {
    type: actionTypes.PORTFOLIO_GRID_LOADING,
    data
  }
);

export const addSaveViewData = data => (
  {
    type: actionTypes.ADD_SAVE_VIEW_DATA,
    data: {
      views: data.views,
      isOpenCustomViewModal: data.isOpenCustomViewModal,
      isModalLoading: data.isModalLoading
    }
  }
);

export const addEditDeleteViewData = data => (
  {
    type: actionTypes.ADD_EDIT_DELETE_VIEW_DATA,
    data: {
      views: data.views,
      isOpenCustomViewModal: data.isOpenCustomViewModal,
      isModalLoading: data.isModalLoading,
      groupBy: data.groupBy
    }
  }
);

export const modalLoading = data => (
  {
    type: actionTypes.MODAL_LOADING,
    data
  }
);

export const startExportToExcel = data => (
  {
    type: actionTypes.START_EXPORT_TO_EXCEL,
    data
  });

export const startExportToPdf = (data) => (
  {
    type: actionTypes.START_EXPORT_TO_PDF,
    data
  }
);
export const changeFileType = data => (
  {
    type: actionTypes.CHANGE_PORTFOLIO_DOWNLOAD_FILE_TYPE,
    data
  }
);
export const changeFitToPageType = data => (
  {
    type: actionTypes.CHANGE_PORTFOLIO_FIT_TO_PAGE_TYPE,
    data
  }
);
