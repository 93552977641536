import findIndex from 'lodash/findIndex';
import intersectionBy from 'lodash/intersectionBy';
import extend from 'lodash/extend';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { createSelector } from 'reselect';
import {FILE_DOWNLOAD_ORIGIN, FILE_DOWNLOAD_TYPES} from '../../constants/appConstants';
import { labelSelector, draftTradesInBlotterSelector, isTradeSaveFailed, snackbarDataList as appSnackbarDataList} from '../app';
import { isTradingDisabledSelector } from '../containers/tradeBlotter';
import errorTypes, {errorMessages} from '../../error/errorType';
import {caseInsensitiveSorter} from '../../utils/comparators';
import {getFileDownloadSignaturesSelector} from '../app/reports';
import {
  PORTFOLIO_FILTER_STATE, VIEW_ACTION, COLUMN_COLID, oldKeyMapping, MAX_VIEWS,
  GROUP_NO_LABEL, SORT, LOCKED_COLUMN_CONFIG, RESET_PINNED_COLUMN_CONFIG, SHOW_ERROR_ON,
  TRADE_COLUMN_CONFIG, GROUP_AGGREGATOR_SPLITTER, INVESTMENT_POLICY, POLICYSCOPE_AND_FUNDNAME
} from '../../constants/pageConstants';
import {isTradingAllowedSelector, isInvestmentPolicyAllowedSelector, allowFeaturesSelector} from '../user/index';
import translator from '../../services/translator';
import {entitledToRebates} from "../containers/fundFactView";


const {translate: t} = translator;

const defaultTotalObj = {};
const defaultErrorObj = {};
const defaultFilterBy = [];
const defaultSelectedRow = [];
const defaultSelectedView = {};

// ADD selector only pagaData
export const groupColumns = state => state.pageData.groupColumns;
export const columnsMetadata = state => state.pageData.columnsMetadata && state.pageData.columnsMetadata.columns || [];

// ADD selector only pageContext
export const portfolioResponse = state => state.pageContext.portfolioData;
export const getTotals = (state) => state.pageContext.totals || defaultTotalObj;
export const isLoading = state => state.pageContext.loading; // Don't assign any fallback
export const modelActiveMode = state => state.pageContext.modelActiveMode;
export const isModalLoading = state => state.pageContext.isModalLoading;
export const isFilterInvalid = state => state.pageContext.isFilterInvalid;
export const allViewsSelector = state => state.pageContext.views || [];
export const selectedViewId = state => state.pageContext.selectedViewId;
export const getFilters = state => state.pageContext.filterBy || defaultFilterBy;
export const getFilterModel = state => state.pageContext.filterModel || null;
export const isOpenCustomViewModal = state => state.pageContext.isOpenCustomViewModal || false;
export const filterVisibility = state => state.pageContext.filterVisibility || PORTFOLIO_FILTER_STATE.HIDDEN;
export const selectCollapseState = state => state.preferences.portfolio ? state.preferences.portfolio.isCollapsedAll : false;
export const getBaseCcy = state => state.preferences.global && state.preferences.global.baseCcy;
export const firms = state => state.preferences.global && state.preferences.global.selectedFirms;
export const includeZBPSelector = state => state.preferences.portfolio ? state.preferences.portfolio.includeZeroPositions : true ;
export const duplicateViewName = state => state.pageContext.duplicateViewName;
export const checkIsPageInErrorMode = state => state.pageContext.isPageInErrorMode || false;
export const getErrorObject = state => state.pageContext.errorObject || defaultErrorObj;
export const showErrorOn = state => ((state.pageContext.errorObject && state.pageContext.errorObject.showErrorOn) || '');
export const exportExcel = state => state.pageContext.portfolioExportExcel;
export const getLastEvent = state => state.pageContext.portfolioData && state.pageContext.portfolioData.event;
export const getSelectedFileType = state => state.pageContext.portfolioFileDownloadType;
export const getSelectedFitToPageType = state => state.pageContext.portfolioFitToPageType;

export const selectedRows = createSelector(
  draftTradesInBlotterSelector,
  (draftTrades) => {
    return draftTrades && draftTrades.length && draftTrades.map(trade => ({id: trade.account.id})) || defaultSelectedRow;
  }
);

export const gridData = createSelector(
  portfolioResponse,
  (portfolioResponse) => {
    if(portfolioResponse && portfolioResponse.portfolioData && portfolioResponse.portfolioData.length){
      return portfolioResponse.portfolioData;
    }
    return [];
  }
);

export const gridDataErrorCode = createSelector(
  portfolioResponse,
  (portfolioResponse) => {
    if(portfolioResponse && portfolioResponse.errorCode){
      return portfolioResponse.errorCode;
    }
    return '';
  }
);

export const groupByField = (state) => {
  if(!state.pageContext.groupBy && state.pageContext.groupBy !== '' ) {
    return 'none';
  }
  else if(state.pageContext.groupBy === ''){
    return 'none';
  }
  else {
    return state.pageContext.groupBy;
  }
};


export const startExcelExport = createSelector(exportExcel, (exportExcel) => {
  return exportExcel;
});

export const checkForNoActiveView = createSelector(
  allViewsSelector,
  (allViews) => {
    return allViews.filter(view => view.isActive).length < 1;
  });

export const currentViewSelector = createSelector(
  allViewsSelector,
  isTradingAllowedSelector,
  isTradingDisabledSelector,
  (allViews, canTrade, isTradingDisabled) => {
    const currentView = allViews.filter(view => view.isActive).length ? allViews.filter(view => view.isActive)[0] : {};
    Object.keys(oldKeyMapping).forEach(newKey => {
      const oldKey = oldKeyMapping[newKey];
      currentView[oldKey] = currentView[newKey];
    });
    if(currentView && currentView.columns){
      if((!canTrade || isTradingDisabled)){
        return { ...currentView, columns: currentView.columns.filter(column => column.colId !== COLUMN_COLID.TRADE)};
      } else {
        const index = currentView.columns.findIndex(column => column.colId === COLUMN_COLID.TRADE);
        if(index === -1){
          let colIndex = 0;
          const columns = [{...TRADE_COLUMN_CONFIG, columnIndex: colIndex}];
          currentView.columns.forEach(column => {
            if(column.hasOwnProperty('columnIndex')){
              columns.push({ ...column, columnIndex: ++colIndex});
            }
          });
          return { ...currentView, columns};
        }
      }
    }
    return currentView;
  }
);

export const getFileType = createSelector(
  getSelectedFileType, currentViewSelector, (fileType, currentView) => {
    return fileType || currentView.downloadFileType || FILE_DOWNLOAD_TYPES.EXCEL;
  }
);

export const getFitToPageType = createSelector(
  getSelectedFitToPageType, currentViewSelector, (fitToPage, currentView) => {
    return fitToPage || currentView.fitPdfToSinglePage || false
  }
);

export const groupByFieldNew = createSelector(
  currentViewSelector,
  (currentViewSelector) => {
    return currentViewSelector.groupBy || '';
  }
);

export const getGroupColumns = createSelector(
  groupColumns,
  isInvestmentPolicyAllowedSelector,
  (columns, isInvtPlcyAllowed) => {
      return !isInvtPlcyAllowed && columns && columns.filter(data =>  data.value !== POLICYSCOPE_AND_FUNDNAME.POLICYSCOPE_AND_FUNDNAME) || columns;
  }
);

export const selectedModelView = createSelector(
  allViewsSelector,
  selectedViewId,
  (allview, id) => {
    if(VIEW_ACTION.id === id){
      return allview.find((view) => view.isActive);
    }
    return allview.find((view) => view.id === id) || defaultSelectedView;
  });


export const groupByColumns = createSelector(
  getGroupColumns,
  labelSelector,
  currentViewSelector,
  (groupColumns, labels, currentView) => {
    const {baseView, groupBy = ''} = currentView;
    return groupColumns && groupColumns.reduce((filtered, column) => {
      if(column.allowedOnViews && column.allowedOnViews.indexOf(baseView) >= 0 || !baseView) {
        filtered.push({value: column.value, label: labels[column.label].toUpperCase(), selected: groupBy === column.value, nodeId: column.value, hide: column.hide});
      }
      return filtered;
    }, []);
  }
);

export const selectedGroup = createSelector(
  groupByField,
  groupByColumns,
  (selectedGroupValue, groupByColumns) => {
    const selectedGroupLabel = groupByColumns
      && groupByColumns.filter(group => group.value === selectedGroupValue)[0].label;
    return {
      value: selectedGroupValue,
      label: selectedGroupLabel
    };
  }
);


export const metaDataSelector = createSelector (
  columnsMetadata,
  labelSelector,
  isInvestmentPolicyAllowedSelector,
  entitledToRebates,
  (metaData, labels, isInvestmentPolicyAllowed, entitledToRebates) => {
    const columnsWithInvestmentPolicyCheck = !isInvestmentPolicyAllowed && metaData.filter(data =>  data.category !== 'tkInvestmentPolicy') || metaData;
    const columnsWithRebatesCheck = !entitledToRebates && columnsWithInvestmentPolicyCheck.filter(
      data => data.headerName !== 'tk1DayAdjYield' && data.headerName !== 'tk7DayAdjCurYield' && data.headerName !== 'tk7DayAdjEffYield' && data.headerName !== 'tk30DayAdjYield'
    ) || columnsWithInvestmentPolicyCheck;
    return columnsWithRebatesCheck.map( column => {
      const columnData = {...column};
      columnData.headerTooltip = labels[column.headerName];
      columnData.headerName = labels[column.headerName];
      if(column.category) {
        columnData.category = labels[column.category];
      }
      return columnData;
    });
  }
);

export const getCurrentGridView = createSelector(
  currentViewSelector,
  columnsMetadata,
  groupByColumns,
  labelSelector,
  (viewColumns, metadata, groupByColumns, labels) => {
    const columns = get(viewColumns, 'columns', []);
    if (columns && groupByColumns) {
      const intersectionColumns = intersectionBy(columns, metadata, 'field');
      const data = intersectionColumns.map(column => {
        const metadataObject = metadata.find(data => data.field === column.field);
        const category = metadataObject.category
          ? { category: labels[metadataObject.category] }
          : {};
        const transformedName = {
          headerTooltip: labels[metadataObject.headerName],
          headerName: labels[metadataObject.headerName],
        };
        return extend({}, metadataObject, column, transformedName, category);
      });

      const intersectionGroupColumns = [];
      groupByColumns.forEach(groupColumn => {
        const object = metadata.find(metaColumn => {
          return groupColumn.value === metaColumn.field;
        });
        if (object) {
          const headerName = get(object, 'headerName', '');
          intersectionGroupColumns.push({
            ...object,
            headerTooltip: labels[headerName] || headerName,
            headerName: labels[headerName] || headerName,
          });
        }
      });
      intersectionGroupColumns.forEach(groupColumn => {
        const index = findIndex(data, viewsColumn => {
          return groupColumn.field === viewsColumn.field;
        });
        if (index >= 0) {
          // if hide is set on `groupColumn` set it on `data` as well; otherwise, default to false
          data[index].hide = !!groupColumn.hide;
        } else if (index === -1) {
          groupColumn.hide = true;
          data.push(groupColumn);
        }
      });
      data.forEach(header => {
        // if hide is not set, make it false
        header.hide = !!header.hide;
        const { dependentColumns = [] } = header;
        if (dependentColumns.length) {
          header.lockVisible = data.some(item => dependentColumns.includes(item.field));
        }
      });

      return sortBy(data, header => header.columnIndex);
    }
    return [];
  }
);

export const columnSortingState = createSelector(
  getCurrentGridView,
  (currentViewColumns) => {
    const sortedColumn = currentViewColumns.filter((column) => (column.sort === SORT.ASC || column.sort === SORT.DESC));
    if(sortedColumn.length > 1){
      const column = sortedColumn.filter(column => column.colId !== COLUMN_COLID.GROUP) || [{}];
      return column[0];
    }
    return sortedColumn[0] || {};
  }
);

export const preDefinedSortingState = createSelector(
  getCurrentGridView,
  (currentViewColumns) => {
    return currentViewColumns.filter((column) => (column.sort === SORT.ASC || column.sort === SORT.DESC)) || [];
  }
);

export const sortBySelector = createSelector (
  getCurrentGridView,
  (currentView) => {
    const coulmnsSortedArray =[];
    if(currentView.length > 0) {
      currentView.forEach((column) => {
        if (column.sort === SORT.ASC || column.sort === SORT.ASC) {
          coulmnsSortedArray.push({columnId: column.headerName, sort: column.sort});
        }
      });
      return coulmnsSortedArray;
    }else{
      return [];
    }
  });

export const filterByMappedData = createSelector (
  getCurrentGridView,
  getFilters,
  (currentView, filterBy) => {
    const coulmnsFilteredArray =[];
    filterBy.forEach((filteredColumn) => {
      const columnFound = currentView.find(column => filteredColumn.field === column.colId);
      if (columnFound){
        coulmnsFilteredArray.push({colId: columnFound.headerName, term: filteredColumn.term});
      }
    });
    return coulmnsFilteredArray;
  });

export const columnSortingStateMappedData = createSelector (
  getCurrentGridView,
  columnSortingState,
  sortBySelector,
  (currentView, sortByState, sortBy) => {
    const mappedSortColumn = cloneDeep(sortByState);
    if(currentView.length > 0 && Object.keys(mappedSortColumn).length > 0) {
      const column = currentView.find((column) => mappedSortColumn.colId === column.colId);
      if(column){
        mappedSortColumn.colId = column.headerName;
      }
      return [mappedSortColumn];
    }else{
      return sortBy;
    }
  });


export const mapViewDataSelector= createSelector(
  allViewsSelector,
  (allViews) => {
    return allViews.map((view) => {
      return {value: view.id, label: view.name, selected: view.isActive, nodeId: view.id, isCustom: view.isCustom};
    });
  }
);

export const customViewsSelector = createSelector(
  mapViewDataSelector,
  (allViews) => {
    return allViews.filter(view => view.isCustom);
  }
);

export const presetViewsSelector = createSelector(
  mapViewDataSelector,
  (allViews) => {
    return allViews.filter(view => !view.isCustom);
  }
);

export const canViewAccountDetailsSelector = createSelector (
  allowFeaturesSelector,
  (allowFeatures) => {
    return allowFeatures.includes("ACCOUNTDETAILS")
  }
);

export const gridHeader = createSelector(
  getCurrentGridView,
  groupByFieldNew,
  preDefinedSortingState,
  canViewAccountDetailsSelector,
  (headerData = [], groupByField, sortedColums, canViewAccountDetails) => {
    if(!headerData.length) return [];
    const groupFieldArr = [];
    if(groupByField && groupByField.includes('And')){
      groupByField.split('And').forEach((token) => {
        groupFieldArr.push(token.trim());
      });
    }else{
      groupFieldArr.push(groupByField);
    }
    const groupColIndex = findIndex(headerData, (colDef) => colDef.colId === COLUMN_COLID.GROUP);
    let hasSetPinnedColumn = false;
    for (let i = groupColIndex+1; i<headerData.length; i++) {
      if (!hasSetPinnedColumn && !headerData[i].hide) {
        headerData[i] = (groupFieldArr[0] !== 'none') ? {...headerData[i], ...LOCKED_COLUMN_CONFIG} : headerData[i];
        hasSetPinnedColumn = true;
      } else {
        headerData[i] = {...headerData[i], ...RESET_PINNED_COLUMN_CONFIG};
      }
    }

    let index = 1;
    const groupedHeaders =  headerData.map(item => {
      delete item.rowGroupIndex;
      if(item.hasOwnProperty('width')){
        item.suppressSizeToFit = true;
      }
      item.rowGroup = groupFieldArr.includes(item.field);
      if(item.rowGroup){
        item.rowGroupIndex = groupFieldArr.indexOf(item.field);
      }
      if(item.hide) {
        item.columnIndex = '';
      } else {
        item.columnIndex = index;
        index++;
      }
      return item;
    }) || [];

    const headers = sortBy(groupedHeaders, (header) => header.columnIndex);
    const newHeaders = headers.map(a => ({...a}));
    if(sortedColums.length > 1){
      const isGroupedColumn = newHeaders.findIndex(column => column.colId === COLUMN_COLID.GROUP);
      if(isGroupedColumn !== -1){
        delete newHeaders[isGroupedColumn].sort;
      }
    }

    newHeaders.forEach(header => {
      if(header.sort === undefined || header.sort === 'none'){
        delete header.sort;
      }
    });

    if (!canViewAccountDetails) {
      const accountDetailsColumns = ['accountName', 'taAccountNumber', 'account'];
      return newHeaders.map(item => {
        return (accountDetailsColumns.includes(item.colId)) ? {...item, cellRenderer: null} : item;
      });
    }
    return newHeaders;
  }
);

export const dropdownViewNamesSelector = createSelector(
  customViewsSelector,
  presetViewsSelector,
  isInvestmentPolicyAllowedSelector,
  labelSelector,
  (customViews, presetViews, isInvestmentPolicyAllowed, labels) => {
    const extPresetViews = !isInvestmentPolicyAllowed && presetViews.filter(data =>  data.label !== INVESTMENT_POLICY.INVESTMENT_POLICY) || presetViews;
    const totalViews = customViews.length + extPresetViews.length;
    const extendObj = totalViews >= 10 ? {} : {type: 'add', nodeId:'add-view-portfolio'};
    const customView = extend(extendObj, totalViews >= 10 ?  MAX_VIEWS : VIEW_ACTION); // button to create a new view
    customView.label = labels[totalViews >=  10 ? MAX_VIEWS.name : VIEW_ACTION.name];
    let views = [];
    if(customViews.length > 0){
      views = [...customViews.sort(caseInsensitiveSorter)];
      if(extPresetViews.length > 0) {
        views.push('-');
      }
      return [...views, ...extPresetViews.sort(caseInsensitiveSorter)];
    }
    else {
      return [...extPresetViews.sort(caseInsensitiveSorter)];
    }
  }
);


export const getCreateCustomViewCTALabel = createSelector(
  customViewsSelector,
  presetViewsSelector,
  labelSelector,
  (customViews, presetViews, labels) => {
    return labels[(customViews.length + presetViews.length) >=  10 ? MAX_VIEWS.name : VIEW_ACTION.name];
  }
);

export const uniqueColumnsSelector = createSelector(
  metaDataSelector,
  columns => {
    const headers = [];
    columns.forEach(column => {
      if(column.hasOwnProperty('category')) {
        headers.push(column.category);
      }
    });
    const uniqueHeaders = uniq(headers).sort();
    if(uniqueHeaders.indexOf('Required') !== -1){
      uniqueHeaders.splice(uniqueHeaders.indexOf('Required'), 1);
      uniqueHeaders.unshift('Required');
    }
    return uniqueHeaders;
  }
);

export const groupedColumnsSelector = createSelector(
  uniqueColumnsSelector,
  metaDataSelector,
  selectedModelView,
  (uniqueColumns, allColumns, currentView) => {
    const sortedColumns = allColumns.sort((a, b) => {
      return a.headerName.localeCompare(b.headerName);
    });
    const groupedColumns = uniqueColumns.map(uniqueColumn => {
      const uniqueColumnObj = {category: uniqueColumn, headers: []};
      sortedColumns.forEach(column => {
        if(column.category === uniqueColumn) {
          uniqueColumnObj.headers.push(column);
        }
      });
      return uniqueColumnObj;
    });
    const currentViewFields = [];
    currentView && currentView.columns && currentView.columns.forEach(column => {
      if(!column.hide){
        currentViewFields.push(column.field);
      }
    }
    );
    return groupedColumns.map(group => {
      const groupCopy = {...group};
      const updatedGroup = groupCopy.headers.map(header => {
        const headerCopy = {...header};
        headerCopy.isChecked = currentViewFields.indexOf(headerCopy.field) > -1;
        return headerCopy;
      });
      groupCopy.headers = updatedGroup;
      return groupCopy;
    });

  }
);

// TODO :: @lohe This is not a way to mute direct data if you use helper function it must return here you are internally muting the data
const undefinedGroupingFields = (group, groupBy, label, parentGroup, parentGroupBy) => {
  if(GROUP_NO_LABEL.includes(group.label)){
    group.details.forEach(dataItem => {
      dataItem[groupBy] = label;
    });
  }if(parentGroup && GROUP_NO_LABEL.includes(parentGroup.label)){
    group.details.forEach(dataItem => {
      dataItem[parentGroupBy] = label;
    });
  }
};

export const getGridData = createSelector(
  gridData,
  groupByField,
  labelSelector,
  (gridData, groupBy, labels) => {
    const allColumns= [];
    gridData.forEach((group) => {
      const groupByArray = groupBy.split('And');
      undefinedGroupingFields(group, groupByArray[0], labels.tkCopyPort10);
      if(group.children && group.children.length > 0){
        group.children.forEach((data) => {
          undefinedGroupingFields(data, groupByArray[1], labels.tkCopyPort10, group, groupByArray[0]);
          allColumns.push(...data.details);
        });
      } else {
        allColumns.push(...group.details);
      }
    });
    return allColumns;
  }
);

export const getGridAggregatedData = createSelector(
  gridData,
  groupByField,
  labelSelector,
  (gridData, groupBy, labels) => {
    const totals = [];
    gridData.forEach((group) => {
      if(group.label !== 'NONE') {
        if (groupBy && groupBy.split('And').length > 1) {
          const groupByArray = groupBy.split('And');
          if(GROUP_NO_LABEL.includes(group.label)) {
            group.label = labels.tkCopyPort10;
          }
          totals.push({...group.totals, groupKey: groupByArray[0], label: group.label});

          if (group.children && group.children.length > 0) {
            group.children.forEach((group2) => {

              if(GROUP_NO_LABEL.includes(group2.label)) {
              /* Using |#|&|#| as double grouping label separator to avoid separator being part of any string */
                totals.push({...group.totals, groupKey: groupByArray[1], label:  `${group.label}${GROUP_AGGREGATOR_SPLITTER}${labels.tkCopyPort10}`});
              } else {
                totals.push({...group2.totals, groupKey: groupByArray[1], label: `${group.label}${GROUP_AGGREGATOR_SPLITTER}${group2.label}`});
              }
            });
          }
        } else if(GROUP_NO_LABEL.includes(group.label)) {
          totals.push({...group.totals, groupKey: groupBy, label: labels.tkCopyPort10});
        } else {
          totals.push({...group.totals, groupKey: groupBy, label: group.label });
        }
      }
    });
    return totals;
  });

// to handle snackbar error
// don't add any extra selectors here, only for error
export const snackbarErrorList = createSelector(
  labelSelector, getErrorObject, (labels, errorObject) => {

    const list = [];
    /**
     * if SHOW_ERROR_ON.SNACKBAR is true errorObject.errorCode will always be UNKNOWN_SERVICE_ERROR
     * because we are setting it from errorHandler
     * */
    if(errorObject.showErrorOn === SHOW_ERROR_ON.SNACKBAR && (errorObject.errorCode === errorTypes.UNKNOWN_SERVICE_ERROR)){
      const message = errorObject.errorMessage && labels[errorObject.errorMessage] ||  labels[errorMessages[errorObject.errorCode].tkBody];
      list.push({displayMultiple:true, id: new Date().toLocaleString(), type:'error', msgCopy: message});
    }
    return list;
  }
);

export const snackbarDataList = createSelector(
  getTotals, gridDataErrorCode, labelSelector, getGridData, getErrorObject, isTradingDisabledSelector, isTradeSaveFailed,
  (totals, errorCode, labels, getGridData, errorObject, isTradingDisabled, isTradeSaveFailed) => {

    const list = [];
    const dataLength = getGridData && getGridData.length;
    if(totals && totals.isMissingData && (totals.isMissingData.length !== 0)){
      list.push({displayMultiple:false, type:'alert', msgCopy:labels.tkCopyPort09, autoHide: false, showCloseCTA: true});
    }

    if(errorCode === errorTypes.TOO_MUCH_DATA){
      list.push({displayMultiple:true, type:'error', msgCopy:labels.tkCopyPort01, id: Date.now()});
    }else if( dataLength > labels.tkMaxDataRowLowLimit){
      list.push({displayMultiple:true, type:'warning', msgCopy:labels.tkCopyPort02});
    }

    if(isTradingDisabled){
      list.push({displayMultiple:true, type:'error', msgCopy:labels.BLOTTER_SAVE_FAILED_MULTIPLE});
    }

    if(isTradeSaveFailed){
      list.push({...isTradeSaveFailed, id: new Date().toLocaleString()});
    }

    return list;
  }
);

export const snackbarList = createSelector(
  snackbarErrorList, appSnackbarDataList, snackbarDataList, (errors, globalMsgs, messages) => {
    return [...errors, ...globalMsgs, ...messages];
  }
);

export const noRowDataSelector = createSelector (isLoading, includeZBPSelector, labelSelector, getGridData, getFilters,
  isFilterInvalid, portfolioResponse, firms,
  (loading, includeZBP, labels, getGridData, filters, invalidFilter, portfolioData, firms) => {
    if(invalidFilter){
      return labels.tkCopyPort17;
    } else if( (loading === undefined) || (getGridData && getGridData.length) ){
      return undefined;
    } else if(filters.length){
      return labels.tkCopyPort04;
    } else if(firms && firms.length === 0 || includeZBP && portfolioData && !portfolioData.errorCode){
      return labels.tkNoFirmsAvailable;
    } else if(!includeZBP && portfolioData && !portfolioData.errorCode){
      return labels.tkCopyPort03;
    } else if(portfolioData && portfolioData.errorCode === errorTypes.TOO_MUCH_DATA){
      return labels.tkNoResultsFound;
    }
  });

export const viewName = createSelector (currentViewSelector, (currentView) => {
  return currentView.id || '';
});

/*
* File Download -  file types optons
*
* */

const getFileTypeOptions = () => {
  const fileTypesOptions = [
    {
      id: FILE_DOWNLOAD_TYPES.EXCEL,
      label: t('tkXLS'),
      value: FILE_DOWNLOAD_TYPES.EXCEL,
      isSelected: true
    },
    {
      id: FILE_DOWNLOAD_TYPES.PDF,
      label: t('tkPDF'),
      value: FILE_DOWNLOAD_TYPES.PDF
    }];
  return fileTypesOptions;
};

export const getFileTypes = createSelector(getFileType, getFileTypeOptions,
  (selectedFile, options) => {
    return options.map((option) => {
      return {
        value: option.id,
        label: option.label,
        isSelected: selectedFile === option.id};
    });
  });


export const disableFileDownload = createSelector(
  getGridData, getFileDownloadSignaturesSelector,
  (data, files) => {

    const portfolioFiles = files && files.filter(file => {
      return file.origin ===  FILE_DOWNLOAD_ORIGIN.PORTFOLIO;
    }) || [];
    return !data.length || portfolioFiles.length > 0;
  }
);

// end  portfolio selectors code
