import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import XLSX from "xlsx-js-style";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Highcharts from "highcharts/highstock";
import ReactToPrint from "react-to-print";
import { Alert } from '@gs-ux-uitoolkit-react/alert';
import moment from "moment-timezone";
import cn from "classnames";
import isEmpty from 'lodash/isEmpty';
import { FILE_DOWNLOAD_TYPES } from '../../../../constants/appConstants';
import { controlPanleMapDispatchToProps } from '../../../../modules/Grid/mapDispatchToProps';
import { isOpenFileDownloadModal } from '../../../../selectors/app/reports';
import {getFileTypes, getSelectedFileType, metricsOptionsSelector} from '../../../../selectors/pages/fundTracker';
import Modal from "../../../../components/core/Modal";
import RadioGroup from "../../../../components/app/RadioGroup";
import Checkbox from "../../../../components/core/CheckBox";
import Select from "../../../../components/core/Select/Simple";
import {simpleRenderer} from "../ChartDropdown/renderer";
import Button from "../../../../components/core/Button";
import {useChartContext} from "../../context";
import {getFundDisplay} from "../../utils";
import {fundIdentifierPreference} from "../../../../selectors/preferences";
import {pageIdSelector} from "../../../../selectors/pages";
import translator from '../../../../services/translator';
import {Conditional} from "../../../../components/core/Conditional";
import {fetchFundTrackerExportData} from '../../services';
import {useAsync} from '../../reducers/Hooks';
import {attributes, getData, setSheetStyle, getWidth, setCoverPageSheetStyle, setDisclaimerStyle} from './exportUtils';
import {ThreeSquaresLoader} from "../../../../components/core/Loaders";
import './index.scss';
import {userSelector} from '../../../../selectors/user';
import {staticDisclaimersSelector} from '../../../../selectors/app';
import {saveAppPreferences} from '../../../../actions/preferences';

const {translate: t} = translator;

const twoYearTimeInSecs = (2 * 365 * 24 * 60 * 60);

export const FundTrackerDataDownloadModal = (props) => {
  const {
    metricOptions, fundIdentifier, currentPageId, selectedFileType, open,
    options, dispatchChangeFileType, dispatchCloseModal,
    componentRef, isPrintingInProgress, setIsPrintingInProgress, userInfo, staticDisclaimer, saveAppPreferences
  } = props;
  const {state: chartState, dispatch: chartDispatch} = useChartContext();
  const {run} = useAsync(chartDispatch);
  const {allShareClassData = [], shareclass = {}, chartInstance, metrics, selection, excelExportData} = chartState;
  const fundDisplay = getFundDisplay(shareclass, fundIdentifier);
  const downloadModalRef = useRef();
  const initialId = metricOptions.map(item => (item.value === currentPageId));
  const [checkedIds, setCheckedIds] = useState(initialId);
  const {shareclass: {longName} = {}} = shareclass;
  const startDate = chartInstance && chartInstance.xAxis && Highcharts.dateFormat('%d.%b.%Y', chartInstance.xAxis[0].getExtremes().min);
  const endDate = chartInstance && chartInstance.xAxis && Highcharts.dateFormat('%d.%b.%Y', chartInstance.xAxis[0].getExtremes().max);
  const d1 = new Date(startDate).getTime();
  const d2 = new Date(endDate).getTime();
  const getTimeDiff = ((new Date(d2).getTime() - new Date(d1).getTime()) / 1000);
  const lessThan2YearData = (getTimeDiff <= twoYearTimeInSecs);
  const [selectedOption, setSelectedOption] = useState({label: t('tkDaily')});
  const frequencyOptions = useMemo(() => ([
    {label: t('tkDaily')},
    {label: t('tkMonthly')},
    {label: t('tkWeekly')}
  ]));
  const enableMetricSelection = (selection !== 'metrics') && lessThan2YearData;
  const customModalStyle = {overflow: 'hidden'};
  const onBeforeGetContentResolve = useRef(null);
  const [text, setText] = useState("Some cool text from the parent");

  const checkID = (ids) => {
    if (metrics.length > 0) {
      const [primaryMetric, secondaryMetric = {}] = metrics;
      return ids.some(({label}) => (
        [primaryMetric.label, secondaryMetric.label].includes(label)
      ));
    }
    return false;
  };

  useEffect(() => {
    setCheckedIds(initialId)
  }, [metricOptions]);

  useEffect(() => {

  }, [selectedFileType]);

  const onSetCheckedIdsChange = (event) => {
    const updatedCheckedIds = metricOptions.map((item, index) => {
      return (item.value === event) ?
        !checkedIds[index] : checkedIds[index];
    });
    setCheckedIds(updatedCheckedIds);
  };

  const handleClose = () => {
    dispatchChangeFileType(null);
    dispatchCloseModal({'modelActiveMode': '', 'isModalLoading': false});
  };

  const handleFileTypeChange =(fileType) => {
    dispatchChangeFileType(fileType);
  };

  const onFileTypeChange = (event, isChecked, option) => {
    handleFileTypeChange(option.id);
  };

  const onClose = (status) => {
    downloadModalRef.current.closeModal(() => handleClose(status));
  };

  const handleBeforePrint = React.useCallback(() => {
    setIsPrintingInProgress(true);
  }, []);

  const handleAfterPrint = React.useCallback(() => {
    setIsPrintingInProgress(false);
    saveAppPreferences({
      preferenceRootKey: 'fundTracker',
      preferenceData: {
        'downloadFileType': selectedFileType
      }
    });
    downloadModalRef.current.closeModal(() => handleClose());
  }, [selectedFileType]);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setText]);

  useEffect(() => {
    if (text === "New, Updated Text!" && typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = useCallback(() => (
    <button
      className={cn('button button__primary button__primary--small')}
      onClick={() => {setIsPrintingInProgress(true)}}>
      {t('tkDownload')}
    </button>
  ), []);

  const currentDate = moment(new Date()).format("DDMMMYYYYHHmmss").toUpperCase();
  const pdfFileName = `FundTracker_Report_${currentDate}`;

  const subHeading = useMemo(() => {
    let fundInfo = "";
    const dateRange = `${startDate} - ${endDate}`;

    if (allShareClassData.length > 1) {
      fundInfo = `${allShareClassData.length} ${t('tkFundsSelected')}`;
    } else {
      fundInfo = fundDisplay && `${longName} (${fundDisplay})` || longName;
    }
    return `${fundInfo}, ${dateRange}`;
  });

  const updatedMetricOptions = useMemo(() => (
    metricOptions.map((item, index) => {
      const isChecked = enableMetricSelection ? checkedIds[index] : checkID(item.items);
      return {...item, isChecked};
    })
  )) || [];

  const isDownloadButtonDisabled = (selectedFileType === FILE_DOWNLOAD_TYPES.EXCEL)
    && !isEmpty(updatedMetricOptions)
    && !updatedMetricOptions.some(({isChecked}) => isChecked) || false;

  const shareclassIds = allShareClassData.map(i => i.id);

  useEffect(() => {
    if(excelExportData.length > 0){
      excelExportData.sort((a, b) => {
        return shareclassIds.indexOf(a.id) - shareclassIds.indexOf(b.id);
      });
      const workbook = XLSX.utils.book_new();
      const headers = [];
      updatedMetricOptions.forEach((id, index) => {
        id.isChecked && metricOptions[index].items.map(i => headers.push(i.label));
      });

      const coverPageData = [];
      coverPageData.push({label: 'Report Parameters', value:''});
      for(let i=0; i<2; i++){
        coverPageData.push({label: '', value: ''});
      }
      allShareClassData.map((i, index) =>
        index === 0 ? coverPageData.push({label: 'Selected Fund(s):', value: `${i.shareclass.longName}`}) : coverPageData.push({label: '', value: `${i.shareclass.longName}`})
      );
      coverPageData.push({label: '', value: ''});
      coverPageData.push({label: 'Selected Metric(s):', value: `${headers.join(', ')}`});
      const coverPageWorksheet = XLSX.utils.json_to_sheet(coverPageData, {skipHeader: true, origin: 'A7'});
      XLSX.utils.book_append_sheet(workbook, coverPageWorksheet, 'Cover Page' );
      const  width = [0, 0];
      coverPageData.map(row => width[0] = Math.max(row.label.length, width[0]));
      coverPageData.map(row => width[1] = Math.max(row.value.length, width[1]));
      coverPageWorksheet['!cols'] = width.map( w => ({wch: w+25}));

      const startDate = chartInstance && chartInstance.xAxis && Highcharts.dateFormat('%d, %b %Y', chartInstance.xAxis[0].getExtremes().min).toUpperCase();
      const endDate = chartInstance && chartInstance.xAxis && Highcharts.dateFormat('%d, %b %Y', chartInstance.xAxis[0].getExtremes().max).toUpperCase();
      XLSX.utils.sheet_add_json(coverPageWorksheet, [{note: 'Report: FUND TRACKER METRICS'}, {note: `${startDate} - ${endDate}`}], {header:["note"], skipHeader: true, origin:"A1"});
      const timeZone = moment.tz.guess();
      const currentDateWithTime = moment(new Date()).tz(timeZone).format("DD.MMM.YYYY hh:mmA z").toUpperCase();
      XLSX.utils.sheet_add_json(coverPageWorksheet, [{note: `Created by: ${userInfo.firstName.charAt(0)}. ${userInfo.lastName} on ${currentDateWithTime}`, note1: 'Confidential - For Internal Use Only'}], {header:["note", "note1"], skipHeader: true, origin:`A${coverPageData.length+11}`});
      setCoverPageSheetStyle(coverPageData, coverPageWorksheet, allShareClassData);

      let maxWidths = Array(8).fill(0);
      const div = document.createElement("div");
      for(let i=0; i<headers.length ; i++){
        const header = headers[i];
        const data = getData(excelExportData, selectedOption, header, attributes()[header] );
        const worksheet = XLSX.utils.json_to_sheet(data, {origin: 'A2'});
        const productGroup = metricOptions.find(i => i.items.some(item => item.label === header)).label;
        const productGroupValue = metricOptions.find(i => i.items.some(item => item.label === header)).value;
        maxWidths = getWidth(data, maxWidths, worksheet);
        XLSX.utils.sheet_add_json(worksheet, [{note1: `${productGroup} Metrics-${header}`}], {header:["note1"], skipHeader: true, origin:"A1"});
        XLSX.utils.sheet_add_json(worksheet, [{note2: t('tkDynamicDisclosureComponent')}], {header: ["note2"], skipHeader: true, origin: `A${data.length+4}`});

        let text = staticDisclaimer[`fundtracker${productGroupValue}GroupDisclaimerData`];
        text = text.replace( '<br>', '\n');
        div.innerHTML = text;
        XLSX.utils.sheet_add_json(worksheet, [{note3: div.innerText}], {header: ["note3"], skipHeader: true, origin: `A${data.length+6}`});
        setSheetStyle(data, worksheet, div.innerText);
        XLSX.utils.book_append_sheet(workbook, worksheet, header.replace(":", "").length > 31 ? "NSF(MM)" :header.replace(":", "") );
      }
      workbook.SheetNames.forEach((key, index) => {
        if(index !== 0)
         workbook.Sheets[key]['!cols'] = maxWidths.map( (w) => ({wch: w}));
      });

      const disclaimerData = [];
      let text = staticDisclaimer.fundtrackerDisclaimerData;
      updatedMetricOptions.forEach((id) => {
        if(id.isChecked) {
          text = text.concat('\n\n');
          text = text.concat(staticDisclaimer[`fundtracker${id.value}GroupDisclaimerData`]);}
      });
      text = text.replace( '<br>', '\n');
      div.innerHTML = text;
      disclaimerData.push({label: div.innerText});
      const disclaimerWorksheet = XLSX.utils.json_to_sheet(disclaimerData, {skipHeader: true});
      setDisclaimerStyle(disclaimerWorksheet, disclaimerData);
      XLSX.utils.book_append_sheet(workbook, disclaimerWorksheet, 'Disclaimers' );

      const currentDate = moment(new Date()).format("DDMMMYYYYHHmmss").toUpperCase();
      XLSX.writeFile(workbook, `FundTracker_Report_${currentDate}.xlsx`);
    }
  }, [excelExportData]);

  const handleFileDownload = (status) => {
    const fieldValue =[];
    updatedMetricOptions.forEach((id, index) => {
      id.isChecked && metricOptions[index].items.map(i => fieldValue.push(i.value));
    });
    const startDate = chartInstance && chartInstance.xAxis && Highcharts.dateFormat('%Y-%m-%d', chartInstance.xAxis[0].getExtremes().min);
    const endDate = chartInstance && chartInstance.xAxis && Highcharts.dateFormat('%Y-%m-%d', chartInstance.xAxis[0].getExtremes().max);
    fetchFundTrackerExportData(run, chartDispatch, startDate, endDate, shareclassIds, fieldValue);
    saveAppPreferences({
      preferenceRootKey: 'fundTracker',
      preferenceData: {
        'downloadFileType': selectedFileType
      }
    });
    downloadModalRef.current.closeModal(() => handleClose(status));
  };

  return(
    <Modal
      ref={downloadModalRef}
      customModalStyle={customModalStyle}
      suppressScrollOnActive={true}
      customClass="modal--center ft-download-modal__index"
      backdropCustomClass="ft-download-modal__backdrop-index"
      open={open}
      handleClose={handleClose}
      animation={false}
    >
      <Conditional condition={isPrintingInProgress}>
        <ThreeSquaresLoader />
      </Conditional>
      <div className="ft-download-modal">
        <div className="ft-download-modal__heading">{t('tkDownload')}</div>
        <div className="ft-download-modal__sub-heading">{subHeading}</div>
        <div className="ft-download-modal__inputContainer">
          <div className="ft-download-modal__section">
            <div className="ft-download-modal__section--title">{t('tkSelectYourFormat')}:</div>
            <div>
              <RadioGroup
                testId="fundtracker-file-download-radio-group"
                onChange={onFileTypeChange}
                options={options}
              />
            </div>
          </div>
          <Conditional condition={selectedFileType === FILE_DOWNLOAD_TYPES.EXCEL}>
            <>
              <div className="ft-download-modal__section">
                <div  className="ft-download-modal__section--title">
                  {t('tkSelectFundMetricsToInclude')}
                </div>
                <div className="metrics">
                  {
                    updatedMetricOptions.map((item) => (
                      <Checkbox
                        value={item.value}
                        id={item.label}
                        isChecked={item.isChecked}
                        label={item.label}
                        isDisabled={!enableMetricSelection}
                        changeHandler={onSetCheckedIdsChange}
                      />
                    ))
                  }
                </div>
              </div>
              <Conditional condition={!lessThan2YearData}>
                <div className="ft-download-modal__section">
                  <Alert
                    status="information"
                    emphasis="subtle">
                    {t('tkFundMetricsDownloadAlert')}
                  </Alert>
                </div>
              </Conditional>
              <div className="ft-download-modal__section">
                <div  className="ft-download-modal__section--title">{t('tkSelectFrequencyOfFundMetrics')}</div>
                <div className="margin-neg-8px">
                  <Select
                    valueKey="value"
                    clickHandler={(selectedOption => setSelectedOption(selectedOption))}
                    selectedOption={selectedOption}
                    isActive={true}
                    hasError={false}
                    optionRenderer={simpleRenderer}
                    labelKey="label"
                    options={frequencyOptions}
                    testId="test-metrics"
                  />
                </div>
              </div>
            </>
          </Conditional>
        </div>
        <div className="ft-download-modal__buttonRow">
          <Button label={t('tkCancel')}   customClass='button-secondary-small' clickHandler={(event) => onClose(event)} />
          <Conditional condition={selectedFileType === FILE_DOWNLOAD_TYPES.EXCEL}>
            <Button
              label={t('tkDownload')}
              customClass='button-primary-small'
              isDisabled={isDownloadButtonDisabled}
              clickHandler={(event) => handleFileDownload(event)}
            />
            <ReactToPrint
              content={reactToPrintContent}
              documentTitle={pdfFileName}
              onAfterPrint={handleAfterPrint}
              onBeforeGetContent={handleOnBeforeGetContent}
              onBeforePrint={handleBeforePrint}
              removeAfterPrint
              trigger={reactToPrintTrigger}
            />
          </Conditional>
        </div>
      </div>
    </Modal>
  );
};

export const mapStateToProps = (state, {id}) => ({
  open: isOpenFileDownloadModal(state),
  options: getFileTypes(state, id),
  selectedFileType: getSelectedFileType(state, id),
  metricOptions: metricsOptionsSelector(state),
  fundIdentifier: fundIdentifierPreference(state),
  currentPageId: pageIdSelector(state),
  userInfo: userSelector(state),
  staticDisclaimer: staticDisclaimersSelector(state)
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    ...controlPanleMapDispatchToProps(dispatch, ownProps),
    saveAppPreferences: data => dispatch(saveAppPreferences(data)),
  };
};

FundTrackerDataDownloadModal.propTypes = {
  open: PropTypes.bool,
  dispatchCloseModal: PropTypes.func,
  options: PropTypes.array,
  dispatchChangeFileType: PropTypes.func,
  selectedFileType: PropTypes.string,
  metricOptions: PropTypes.array,
  fundIdentifier: PropTypes.string,
  currentPageId: PropTypes.string,
  componentRef: PropTypes.object,
  isPrintingInProgress: PropTypes.bool,
  setIsPrintingInProgress: PropTypes.func,
  userInfo: PropTypes.object,
  staticDisclaimer: PropTypes.object,
  saveAppPreferences: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(FundTrackerDataDownloadModal);
