import { createSelector } from 'reselect';
import isUndefined from 'lodash/isUndefined';
import {
  SORT, PORTFOLIO_FILTER_STATE, MODULES_KEYS, COLUMN_COLID
} from '../../../constants/pageConstants';
import { labelSelector, firmsSelector } from '../../app';
import { filteredDataLength, isLoading, allViewsSelector, gridData, gridError, groupByFieldNew, columnsMetadata, groupColumns, groupBy, getFilteredDataLength, filterVisibility, getFilters, getFilterInErrorState } from '../../../modules/Grid/selector';
import { getCurrentViewSelectorData, getCurrentGridViewData, gridHeaderData } from '../../../helpers/gridFund';
import errorTypes from '../../../error/errorType';
import {getFileDownloadSignaturesSelector} from '../../app/reports';
import {FILE_DOWNLOAD_ORIGIN, FILE_DOWNLOAD_TYPES} from '../../../constants/appConstants';
import translator from '../../../services/translator';

const {translate: t} = translator;

export const showHomeAway = ({modules}) => {
  const id = MODULES_KEYS.CURRENT_TRADES;
  return modules && modules[id] && modules[id].isShowHomeAway;
};
const defaultCurrentView = {};
const defaultErrorObject = {};
export const getErrorObject = state => state.pageContext.errorObject || defaultErrorObject;
export const selectCollapseState = ({preferences = {}}) => preferences.currentTradeStatus ? preferences.currentTradeStatus.isCollapsedAll : false;
export const getSelectedFileType = state => state.modules.currentTradeStatus.transactionsFileDownloadType;
export const getSelectedFitToPageType = state => state.modules.currentTradeStatus.transactionsFitToPageChangeType;
export const exportExcel = state => state.modules.currentTradeStatus.exportExcel || {};

export const groupByField = createSelector(groupBy, groupByField => groupByField === '' ? 'none' : groupByField || 'none');
export const getDataInlineError = ({modules}, id) => modules[id].dataError;

export const dropdownViewNamesSelector = createSelector(
  allViewsSelector,
  allViewsSelector => allViewsSelector.map(view => ({label: view.name, value: view.id, selected: view.isActive}))
);

// Get Active view
export const currentView = createSelector(
  allViewsSelector, showHomeAway,
  (allViews, isShowHomeAway) => {
    const view = allViews.find(view => view.isActive);
    if(!view) return defaultCurrentView;
    const columns = view.columns.map(column => {
      return column.colId === COLUMN_COLID.HOME_AWAY ? { ...column, hide: !isShowHomeAway } : column;
    });
    return {...view, columns};
  }
);

export const groupByColumns = createSelector(
  groupColumns, labelSelector, groupByFieldNew,
  (groupByColumns, labels, groupByField) => {
    return groupByColumns && groupByColumns.map(item => {
      return {value: item.value, label: labels[item.label].toUpperCase(), selected: groupByField === item.value, nodeId: item.value};
    });
  }
);

export const selectedGroup = createSelector(
  groupByField,
  groupByColumns,
  (selectedGroupValue, groupByColumns) => {
    const selectedGroupLabel = groupByColumns && groupByColumns.length
      && groupByColumns.filter(group => group.value === selectedGroupValue)[0].label;
    return {
      value: selectedGroupValue,
      label: selectedGroupLabel
    };
  }
);

export const currentViewSelector = createSelector(
  currentView,
  currentView => getCurrentViewSelectorData({currentView, canTrade: false, isTradingDisabled: true})
);

export const getFileType = createSelector(
  getSelectedFileType, currentViewSelector, (fileType, currentView) => {
    return fileType || currentView.downloadFileType || FILE_DOWNLOAD_TYPES.EXCEL;
  }
);

export const getFitToPageType = createSelector(
  getSelectedFitToPageType, currentViewSelector, (fitToPage, currentView) => {
    return fitToPage || currentView.fitPdfToSinglePage || false
  }
);

export const getCurrentGridView = createSelector(
  currentViewSelector, columnsMetadata, groupByColumns, labelSelector,
  (viewColumns, metadata, groupByColumns = [], labels) => getCurrentGridViewData({viewColumns, metadata, groupByColumns, labels})
);

export const preDefinedSortingState = createSelector(
  getCurrentGridView, currentViewColumns => currentViewColumns.filter((column) => (column.sort === SORT.ASC || column.sort === SORT.DESC)) || []
);

// Grid Columns
export const gridHeader = createSelector(
  getCurrentGridView, groupByFieldNew, preDefinedSortingState, columnsMetadata,
  (headerData = [], groupByField, sortedColums, metadata) => gridHeaderData({headerData, groupByField, sortedColums, metadata})
);

export const getGridData = createSelector(
  gridData,
  groupBy,
  labelSelector,
  (gridData, groupBy, labels) => {
    return gridData.map(rowData => {
      const groupByArray = groupBy.split('And');
      for(let i=0; i<groupByArray.length; i++) {
        if(!rowData[groupBy] && rowData[groupBy] !== 0) {
          return {...rowData, [groupBy]: labels.tkCopyPort10};
        }
      }
      return {...rowData};
    }) || [];
  }
);


export const disableExcelExport = createSelector(
  gridData,
  getFilteredDataLength,
  filterVisibility,
  getFilters,
  getFilterInErrorState,
  (data, filteredData, filterVisibility, filters = [], filterInErrorState) => {
    if(filterInErrorState){
      return true;
    } else if(filterVisibility === PORTFOLIO_FILTER_STATE.HIDDEN){
      return !data.length;
    } else {
      return filters.length ? !filteredData : !data.length;
    }
  }
);

export const getCurrentViewsKeys = createSelector(
  getCurrentGridView,
  columns => {
    const result = {};
    columns.forEach(col => {
      result[col.colId] = ' ';
    });
    return result;
  }
);

export const snackbarDataList = createSelector(
  gridError, labelSelector, getGridData, getDataInlineError, (errorCode, labels, getGridData, dataInlineError = {}) => {
    const list = [];
    const dataLength = getGridData && getGridData.length;
    if((errorCode && errorCode.code === errorTypes.TOO_MUCH_DATA)){
      list.push({displayMultiple:true, type:'error', msgCopy: labels.tkCtsMaxtRowMsg, id: Date.now()});
    } else if( dataLength > labels.tkMaxDataRowLowLimit){
      list.push({displayMultiple:true, type:'warning', msgCopy:labels.tkCopyPort02});
    } else if(dataInlineError.code === errorTypes.TRADE_APPROVAL_TRANSACTION_FETCH_FAIL){ // TODO::
      list.push({displayMultiple:true, type:'error', msgCopy:labels.tkTradeAprovalTransactionFail, id: Date.now()});
    }
    return list;
  }
);

export const noRowDataSelector = createSelector(
  getGridData, gridError, isLoading, labelSelector, firmsSelector, filteredDataLength,
  (data, error, loading, labels, firms = [], filteredData) => {
    if(filteredData === 0) {
      return labels.tkCopyPort04;
    } else if (isUndefined(loading) || (data && data.length)) {
      return;
    } else if(error && error.code  === errorTypes.TOO_MUCH_DATA){
      return labels.tkNoResultsFound;
    } else if(!firms.length){
      return labels.tkNoCtsDataMsg;
    }
    return loading ? '' : labels.tkNoCtsDataMsg;
  }
);

const getFileTypeOptions = () => [
  {
    id: FILE_DOWNLOAD_TYPES.EXCEL,
    label: t('tkXLS'),
    value: FILE_DOWNLOAD_TYPES.EXCEL,
    isSelected: true
  },
  {
    id: FILE_DOWNLOAD_TYPES.PDF,
    label: t('tkPDF'),
    value: FILE_DOWNLOAD_TYPES.PDF
  }
];

export const getFileTypes = createSelector(getFileType, getFileTypeOptions,
  (selectedFile, options) => {
    return options.map((option) => {
      return {
        value: option.id,
        label: option.label,
        isSelected: selectedFile === option.id};
    });
  });


export const disableFileDownload = createSelector(
  gridData,
  getFilteredDataLength,
  filterVisibility,
  getFilters,
  getFilterInErrorState,
  getFileDownloadSignaturesSelector,
  (data, filteredData, filterVisibility, filters = [], filterInErrorState, files) => {

    const transactionsFiles = files && files.filter(file => {
      return file.origin ===  FILE_DOWNLOAD_ORIGIN.TRANSACTIONS;
    }) || [];

    if(filterInErrorState){
      return true;
    } else if(filterVisibility === PORTFOLIO_FILTER_STATE.HIDDEN){
      return !data.length ||  transactionsFiles.length > 0;
    } else {
      return (filters.length ? !filteredData : !data.length) || transactionsFiles.length > 0;
    }
  }
);


