import actionTypes from '../../../actions/page/portfolio/actionTypes';
import { replaceActiveView, updateActiveView } from '../../../helpers/gridFund';

export function portfolioPageDataReducer(state = {}, action) {
  const { type, data = {} } = action;
  switch (type) {

    case actionTypes.ADD_PORTFOLIO_LOADED_DATA: {
      const {columnsMetadata, groupColumns } = data;
      return { ...state, columnsMetadata, groupColumns };
    }

    default:
      return state;
  }
}

export function portfolioPageContextReducer(state = {}, action) {
  const { type, data = {} } = action;
  switch (type) {

    case actionTypes.SAVE_CUSTOM_VIEW:
    case actionTypes.DELETE_CUSTOM_VIEW:
    case actionTypes.EDIT_CUSTOM_VIEW: {
      return { ...state, isModalLoading: true };
    }

    case actionTypes.OPEN_CUSTOM_VIEW_MODAL:
    case actionTypes.CLOSE_CUSTOM_VIEW_MODAL:
    case actionTypes.TOGGLE_ZBA:
    case actionTypes.APPLY_FILTER: {
      return { ...state, ...data };
    }

    case actionTypes.START_EXPORT_TO_EXCEL: {
      return { ...state, portfolioExportExcel: data };
    }

    case actionTypes.ADD_PORTFOLIO_DATA: {
      const { totals, portfolioData, loading } = data;
      return { ...state, totals, portfolioData, loading };
    }

    case actionTypes.PORTFOLIO_GRID_LOADING: {
      return {  ...state, loading: data };
    }

    case actionTypes.CHANGE_GROUP: {
      return { ...state, groupBy: data.groupBy, loading: true};
    }

    case actionTypes.CHANGE_VIEW:
      return { ...state, ...updateActiveView(state.views, data.id)};

    case actionTypes.CHANGE_COLUMN_ORDER:
    case actionTypes.CHANGE_COLUMN_WIDTH: {
      return { ...state, views: replaceActiveView(state.views, data)};
    }

    case actionTypes.CHANGE_COLUMN_SORT: {
      const views = replaceActiveView(state.views, data);
      return { ...state, views };
    }

    case actionTypes.ADD_PORTFOLIO_LOADED_DATA: {
      const { views, groupBy, filterBy, isOpenCustomViewModal, loading} = data;
      return { ...state, views, groupBy, filterBy, isOpenCustomViewModal, loading };
    }

    case actionTypes.ADD_PORTFOLIO_GROUP_CHANGED_DATA: {
      const { views, loading, portfolioData, totals } = data;
      return { ...state, views, loading, portfolioData, totals };
    }

    case actionTypes.ADD_SAVE_VIEW_DATA: {
      const { views, isOpenCustomViewModal, isModalLoading } = data;
      return { ...state, views, isOpenCustomViewModal, isModalLoading };
    }

    case actionTypes.ADD_EDIT_DELETE_VIEW_DATA: {
      const { views, isOpenCustomViewModal, isModalLoading, groupBy } = data;
      return { ...state, views, isOpenCustomViewModal, isModalLoading, groupBy };
    }

    case actionTypes.MODAL_LOADING: {
      return { ...state, isModalLoading: data.isModalLoading };
    }

    case actionTypes.TOGGLE_FILTER_VISIBILITY: {
      return { ...state, filterVisibility: data.filterVisibility, filterBy: [], filterModel: null };
    }
    case actionTypes.CHANGE_PORTFOLIO_DOWNLOAD_FILE_TYPE: {
      return { ...state, portfolioFileDownloadType: data };
    }
    case actionTypes.CHANGE_PORTFOLIO_FIT_TO_PAGE_TYPE: {
      return { ...state, portfolioFitToPageType: data };
    }

    default:
      return state;
  }
}

