import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment/moment';
import appConstants, {FILE_DOWNLOAD_TYPES} from '../../../constants/appConstants';
import DownloadModal from '../../../components/app/Download';
import {closeDownloadModal, queueExcelDownloadSnackbar} from '../../../actions/app/reports/index';
import {
  changeFileType,
  changeFitToPageType,
  editCustomView,
  startExportToExcel,
  startExportToPdf
} from '../../../actions/page/portfolio';
import {
  isOpenFileDownloadModal
} from '../../../selectors/app/reports';

import {
  currentViewSelector,
  getFileTypes,
  getFileType, getFitToPageType, gridHeader
} from '../../../selectors/pages/portfolio';
import {dispatchAnalytics, getExportViewAnalyticsData} from '../analytics';

const {PDF_DOWNLOAD_MAX_COLUMN_WARNING_LIMIT} = appConstants;
export const PortfolioFileDownloadModal =(props) => {


  const handleClose =() => {
    props.dispatchChangeFileType(null);
    props.dispatchFitToPageChangeType(null);
    props.dispatchCloseModal({'modelActiveMode': '', 'isModalLoading': false});
  };

  const handleFileTypeChange =(fileType) => {
    props.dispatchChangeFileType(fileType);
  };

  const handleFitToPageTypeChange = (isChecked) => {
    props.dispatchFitToPageChangeType(isChecked);
  };

  const handleDownload =(fitToPage) => {
    props.dispatchChangeFileType(null);
    props.dispatchFitToPageChangeType(null);
    props.dispatchFileDownload(props, fitToPage);
  };

  const modalProps = {
    animation: false,
    suppressScrollOnActive: true,
    customModalStyle: {overflow: 'hidden'},
    isModalOpen: props.open,
    handleClose,
    title: 'tkPortfolio',
    selectedFileType: props.selectedFileType,
    selectedFitToPageType: props.selectedFitToPageType,
    options: props.options,
    handleFileTypeChange,
    handleFitToPageTypeChange,
    handleDownload,
    isMoreThan20Columns: props.currentView && props.currentView.columns && (props.currentView.columns.length - 2 > PDF_DOWNLOAD_MAX_COLUMN_WARNING_LIMIT)
  };

  return (
    <DownloadModal {...modalProps} />
  );
};


const mapStateToProps = state => (
  {
    open: isOpenFileDownloadModal(state),
    options: getFileTypes(state),
    header: gridHeader(state),
    currentView: currentViewSelector(state),
    selectedFileType: getFileType(state),
    selectedFitToPageType: getFitToPageType(state)
  }
);

export const mapDispatchToProps = dispatch => (
  {
    dispatchCloseModal: data => dispatch(closeDownloadModal(data)),
    dispatchChangeFileType: data => dispatch(changeFileType(data)),
    dispatchFitToPageChangeType: data => dispatch(changeFitToPageType(data)),
    dispatchFileDownload: (props, fitToPage) => {
      const {header, currentView, selectedFileType} = props;
      if(selectedFileType === FILE_DOWNLOAD_TYPES.EXCEL) {
        const viewLabel =  currentView && currentView.label ? currentView.label.split(':')[0].trim().replace(/ /g, '_') : '';
        const fileName = `${viewLabel}_${moment().format('DDMMMYYYY')}`.toUpperCase();
        const id = `excel-snackbar-${new Date().getTime()}`;
        dispatch(queueExcelDownloadSnackbar({fileName, id }));
        dispatch(startExportToExcel({fileName, id }));
        dispatchAnalytics(dispatch, getExportViewAnalyticsData(header, currentView.label, 'Excel'));
        dispatch(editCustomView({...currentView, downloadFileType: selectedFileType, fitPdfToSinglePage: false}));
      } else {
        dispatch(startExportToPdf({fitToPage}));
        dispatchAnalytics(dispatch, getExportViewAnalyticsData(header, currentView.label, 'PDF'));
        dispatch(editCustomView({...currentView, downloadFileType: selectedFileType, fitPdfToSinglePage: fitToPage}));
      }
    }
  }
);

PortfolioFileDownloadModal.propTypes = {
  open: PropTypes.bool,
  dispatchCloseModal: PropTypes.func,
  errorObject: PropTypes.object,
  options: PropTypes.array,
  dispatchChangeFileType: PropTypes.func,
  dispatchFitToPageChangeType: PropTypes.func,
  selectedFileType: PropTypes.string,
  selectedFitToPageType: PropTypes.bool,
  dispatchFileDownload: PropTypes.func,
  currentView: PropTypes.object

};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioFileDownloadModal);
