import map from 'lodash/map';
import get from 'lodash/get';
import moment from "moment/moment";
import {addPageContext} from '../../../actions/page/index';
import {dispatchAnalytics, getColumnSortingAnalyticsData, getColumnSearchAnalyticsData,
  getGridEndLoadAnalyticsData, getColumnRearrangeAnalyticsData, getGroupChangeAnalyticsData,
  getColumnRemoveAnalyticsData, getExpandCollapseAnalyticsData, getExportViewAnalyticsData,
  getGridEndLoadOnSearchAnalyticsData, getViewChangeAnalyticsData } from '../analytics';
import { getNewOrderedColumns, getNewVisibleColumns, getNewWidthedColumns, getNewGroupByViewData } from '../../../helpers/gridFund';
import {addToTradeBlotter, removeFromTradeBlotter} from '../../../actions/sagas';
import { actionsCreators } from '../actionsCreator';
import { editAppPreferences } from '../../../actions/preferences/index';
import {closeDownloadModal, openDownloadModal, queueExcelDownloadSnackbar} from "../../../actions/app/reports";
import {FILE_DOWNLOAD_TYPES} from "../../../constants/appConstants";

let startTime = 0;

export const gridMapDispatchToProps = (dispatch, ownProps) => {

  const { addGridData, gridColumnWidthChange, gridColumnOrderChange,
    gridColumnRemoveChange, gridFilterChange, gridCleanData, gridFilterChangeWithError } = actionsCreators(ownProps.id);

  return {
    dispatchSortChange: (_colId, columnName, order) => {
      return dispatchAnalytics(dispatch, getColumnSortingAnalyticsData(order, columnName));
    },

    dispatchColumnWidthChanged: (columns, props) => {
      const {currentView} = props;
      const updatedView = getNewWidthedColumns(columns, currentView);
      dispatch(gridColumnWidthChange(updatedView));
    },

    dispatchColumnRemove: (colId, _visibility, props) => {
      const {currentView, columnDefs} = props;
      const updatedView = getNewVisibleColumns(colId, currentView);
      dispatch(gridColumnRemoveChange(updatedView));
      const updatedColumnDefs = columnDefs.filter(column => column.colId !== colId);
      dispatchAnalytics(dispatch, getColumnRemoveAnalyticsData(updatedColumnDefs, colId, updatedView.label));
    },

    dispatchColumnMoved: (columns, params, props) => {
      const data = getNewOrderedColumns(columns, props.currentView);
      dispatch(gridColumnOrderChange(data));
      dispatchAnalytics(dispatch, getColumnRearrangeAnalyticsData(get(params, 'column.colDef.headerName')));
    },

    dispatchFilterChange: (filterModel, filteredDataLength) => {
      const filterBy = map(filterModel, (obj, key) => ({field: key, term: obj.filter}));
      dispatch(gridFilterChange({filterBy, filterModel, filteredDataLength}));
    },

    dispatchFilterChangeWithError: () => {
      dispatch(gridFilterChangeWithError());
    },
    dispatchCellClick: event => {
      if(event.colDef.colId === 'fundName' && event.data){
        if(event.data.productLink){
          window.open(event.data.productLink, '_blank');
        } else { // Set active FundFact in order to open fund fact card model
          event.data.factCardType = "Fund";
          dispatch(addPageContext({activeFundFact: event.data}));
        }
      }
    },

    dispatchFilterFocus: data => {
      dispatchAnalytics(dispatch, getColumnSearchAnalyticsData(data.colDef.headerName));
    },

    dispatchGridLoadEnd: (event, totalRecords, columns, selectedView, filterBy, time) => {
      dispatchAnalytics(dispatch, getGridEndLoadAnalyticsData(event, columns, selectedView, totalRecords, filterBy, time));
    },

    dispatchFilterApplied: (_, totalRecords, props) => {
      const { columnDefs, currentView, filterBy } = props;
      dispatch(addGridData({filteredDataLength: totalRecords}))
      dispatchAnalytics(dispatch, getGridEndLoadOnSearchAnalyticsData(filterBy, totalRecords, columnDefs, currentView.label));
    },
    dispatchLoadStart: () => {
      startTime = new Date().getTime()
    },
    dispatchLoadEnd: (props) => {
      const {lastEvent, data, columnDefs, currentView} = props;
      lastEvent && dispatchAnalytics(dispatch, getGridEndLoadAnalyticsData(lastEvent, columnDefs, currentView.label, data.length, null, startTime))
    },

    dispatchAddTrade: data => dispatch(addToTradeBlotter(data)),
    dispatchRemoveTrade: data => dispatch(removeFromTradeBlotter(data)),
    dispatchGridCleanData: () => dispatch(gridCleanData()),
    ...ownProps.overrideGridMapDispatchToProps
  };
};

export const controlPanleMapDispatchToProps = (dispatch, ownProps) => {
  const { gridGroupChange, gridToggleFilterVisisbility, gridToggleGroupCollapse, gridStartExportToExcel,
    gridStartExportToPdf, gridChangeFileType, gridChangeFitToPageType, gridEditCustomView, gridOpenCustomViewModal, gridViewChange, gridSortData } = actionsCreators(ownProps.id)
  return {
    dispatchFilterVisibility: data => {
      dispatch(gridToggleFilterVisisbility(data))
    },
    dispatchGroupChange: ({value, selectedItems, currentView}) => {
      const activeView = getNewGroupByViewData(currentView, value)
      dispatch(gridGroupChange(activeView));
      if (ownProps.allowSortingOnGroupChange) {
        dispatch(gridSortData());
      }
      dispatchAnalytics(dispatch, getGroupChangeAnalyticsData(selectedItems.label));
    },
    dispatchViewChange: (data, viewName, columns) => {
      dispatch(gridViewChange({id: data}));
      if (ownProps.allowSortingOnGroupChange) {
        dispatch(gridSortData());
      }
      dispatchAnalytics(dispatch, getViewChangeAnalyticsData(columns, viewName));
    },
    dispatchToggleGroupCollapse: data => {
      dispatch(gridToggleGroupCollapse(data))
      dispatch(editAppPreferences())
      dispatchAnalytics(dispatch, getExpandCollapseAnalyticsData(data));
    },
    dispatchDownloadExcel: (props) => {
      dispatch(gridStartExportToExcel({}));
      const {currentView, header} = props;
      const id = `excel-snackbar-${new Date().getTime()}`;
      const viewLabel =  currentView && currentView.label ? currentView.label.split(':')[0].trim().replace(/ /g, '_') : '';
      const fileName = `${viewLabel}_${moment().format('DDMMMYYYY')}`.toUpperCase();
      dispatch(queueExcelDownloadSnackbar({fileName, id }));
      dispatchAnalytics(dispatch, getExportViewAnalyticsData(header, currentView.label, 'Excel'));
    },
    dispatchDownloadPdf: (props, data) => {
      dispatch(gridStartExportToPdf(data));
      dispatchAnalytics(dispatch, getExportViewAnalyticsData(props.header, props.currentView.label, 'PDF'));
    },
    dispatchCloseModal: data => dispatch(closeDownloadModal(data)),
    dispatchOpenFileDownloadModal: (props) => {
      dispatch(openDownloadModal(props));
    },
    dispatchChangeFileType: data => dispatch(gridChangeFileType(data)),
    dispatchFitToPageChangeType: data => dispatch(gridChangeFitToPageType(data)),
    dispatchFileDownload: (props, fitToPage) => {
      const {currentView, selectedFileType, header} = props;
      if(selectedFileType === FILE_DOWNLOAD_TYPES.EXCEL) {
        const viewLabel =  currentView && currentView.label ? currentView.label.split(':')[0].trim().replace(/ /g, '_') : '';
        const fileName = `${viewLabel}_${moment().format('DDMMMYYYY')}`.toUpperCase();
        const id = `excel-snackbar-${new Date().getTime()}`;
        dispatch(queueExcelDownloadSnackbar({fileName, id }));
        dispatch(gridStartExportToExcel({fileName, id }));
        dispatchAnalytics(dispatch, getExportViewAnalyticsData(header, currentView.label, 'Excel'));
        dispatch(gridEditCustomView({...currentView, downloadFileType: selectedFileType, fitPdfToSinglePage: false}));
      } else {
        dispatchAnalytics(dispatch, getExportViewAnalyticsData(header, currentView.label, 'PDF'));
        dispatch(gridStartExportToPdf({fitToPage}));
        dispatch(gridEditCustomView({...currentView, downloadFileType: selectedFileType, fitPdfToSinglePage: fitToPage}));
      }
    },
    dispatchOpenCustomViewModal: (open, modelActiveMode, customView = {}) => {
      const params = {
        modelActiveMode: open ? modelActiveMode : '',
        selectedViewId: customView.id,
        open
      };
      dispatch(gridOpenCustomViewModal(params));
    },
    ...ownProps.overrideControlPanelMapDispatchToProps
  };
}
