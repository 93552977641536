import { isEmpty, get } from 'lodash';
import { profileConfig } from './config';
import { TEXT_WITH_SWITCH, TEXT, DROPDOWN, AUTOCOMPLETE_USER_DETAILS } from '../../../constants';
import translator from '../../../../../../services/translator';

const { translate: t } = translator;

export const validateProfileData = async (userDetails) => {
  const validation = {};
  const types = [TEXT, DROPDOWN, AUTOCOMPLETE_USER_DETAILS];
  Object.keys(profileConfig).forEach(groupKey => {
    profileConfig[groupKey].forEach(coreItem => {
      if (coreItem.required || coreItem.validation) {
        const value = get(userDetails, Array.isArray(coreItem.value) ? coreItem.value : coreItem.value.split('.'))
        if (coreItem.validation && coreItem.editableFieldType !== TEXT_WITH_SWITCH) {
          const { error, errorMsg } = coreItem.validation(value) || {}
          validation[coreItem.value] = { isValid: !error, errorMessage: errorMsg };
        } else if (types.includes(coreItem.editableFieldType)) {
          validation[coreItem.value] = { isValid: !isEmpty(value), errorMessage: isEmpty(value) ? `${t(coreItem.label)} ${t('tkIsRequired')}` : '' };
        }
      }
    })
  });
  userDetails.ssoClient.forEach(data => {
    if (data.isEnabled) {
      validation[data.clientName] = { isValid: !!data.alias, errorMessage: !data.alias && t('tkIdentifierCannotBeEmpty') };
    }
  });
  const success = Object.values(validation).every(currentElement => currentElement.isValid === true);
  return { success, validationError: validation };
}