import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import appConstants from '../../../../constants/appConstants';
import DownloadModal from '../../../../components/app/Download';
import { controlPanleMapDispatchToProps } from '../../../../modules/Grid/mapDispatchToProps';
import {
  isOpenFileDownloadModal
} from '../../../../selectors/app/reports';

import {
  currentViewSelector,
  getFileTypes,
  getFileType, getFitToPageType, gridHeader,
} from '../../../../selectors/pages/tradeActivity/currentTrades';

const {PDF_DOWNLOAD_MAX_COLUMN_WARNING_LIMIT} = appConstants;

export const TransactionsFileDownloadModal =(props) => {


  const handleClose =() => {
    props.dispatchChangeFileType(null);
    props.dispatchFitToPageChangeType(null);
    props.dispatchCloseModal({'modelActiveMode': '', 'isModalLoading': false});
  };

  const handleFileTypeChange =(fileType) => {
    props.dispatchChangeFileType(fileType);
  };

  const handleFitToPageTypeChange = (isChecked) => {
    props.dispatchFitToPageChangeType(isChecked);
  };

  const handleDownload =(fitToPage) => {
    props.dispatchChangeFileType(null);
    props.dispatchFitToPageChangeType(null);
    props.dispatchFileDownload(props, fitToPage);
  };

  const modalProps = {
    animation: false,
    suppressScrollOnActive: true,
    customModalStyle: {overflow: 'hidden'},
    isModalOpen: props.open,
    handleClose,
    title: 'tkPortfolio',
    selectedFileType: props.selectedFileType,
    selectedFitToPageType: props.selectedFitToPageType,
    options: props.options,
    handleFileTypeChange,
    handleFitToPageTypeChange,
    handleDownload,
    isMoreThan20Columns: props.currentView && props.currentView.columns && (props.currentView.columns.length - 1 > PDF_DOWNLOAD_MAX_COLUMN_WARNING_LIMIT)
  };

  return (
    <DownloadModal {...modalProps} />
  );
};


export const mapStateToProps = (state, ownProps) => {
  const {id} = ownProps;
  return {
    open: isOpenFileDownloadModal(state, id),
    options: getFileTypes(state, id),
    header: gridHeader(state, id),
    currentView: currentViewSelector(state, id),
    selectedFileType: getFileType(state, id),
    selectedFitToPageType: getFitToPageType(state, id)
  }
}


TransactionsFileDownloadModal.propTypes = {
  open: PropTypes.bool,
  dispatchCloseModal: PropTypes.func,
  errorObject: PropTypes.object,
  options: PropTypes.array,
  dispatchChangeFileType: PropTypes.func,
  dispatchFitToPageChangeType: PropTypes.func,
  selectedFileType: PropTypes.string,
  selectedFitToPageType: PropTypes.bool,
  dispatchFileDownload: PropTypes.func,
  currentView: PropTypes.array

};

export default connect(mapStateToProps, controlPanleMapDispatchToProps)(TransactionsFileDownloadModal);
