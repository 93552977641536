export default {
  ADD_PORTFOLIO_HEADER: 'ADD_PORTFOLIO_HEADER',
  ADD_PORTFOLIO_DETAILS: 'ADD_PORTFOLIO_DETAILS',
  OPEN_CUSTOM_VIEW_MODAL: 'OPEN_CUSTOM_VIEW_MODAL',
  CLOSE_CUSTOM_VIEW_MODAL: 'CLOSE_CUSTOM_VIEW_MODAL',
  SAVE_CUSTOM_VIEW: 'SAVE_CUSTOM_VIEW',
  DELETE_CUSTOM_VIEW: 'DELETE_CUSTOM_VIEW',
  EDIT_CUSTOM_VIEW: 'EDIT_CUSTOM_VIEW',
  APPLY_FILTER: 'APPLY_FILTER',
  CHANGE_GROUP: 'CHANGE_GROUP',
  CHANGE_VIEW: 'CHANGE_VIEW',
  CHANGE_COLUMN_SORT: 'CHANGE_COLUMN_SORT',
  CHANGE_COLUMN_WIDTH: 'CHANGE_COLUMN_WIDTH',
  CHANGE_COLUMN_ORDER: 'CHANGE_COLUMN_ORDER',
  REMOVE_COLUMN: 'REMOVE_COLUMN',
  ADD_PORTFOLIO_LOADED_DATA: 'ADD_PORTFOLIO_LOADED_DATA',
  ADD_PORTFOLIO_DATA: 'ADD_PORTFOLIO_DATA',
  ADD_PORTFOLIO_GROUP_CHANGED_DATA: 'ADD_PORTFOLIO_GROUP_CHANGED_DATA',
  PORTFOLIO_GRID_LOADING: 'PORTFOLIO_GRID_LOADING',
  ADD_SAVE_VIEW_DATA: 'ADD_SAVE_VIEW_DATA',
  ADD_EDIT_DELETE_VIEW_DATA: 'ADD_EDIT_DELETE_VIEW_DATA',
  MODAL_LOADING: 'MODAL_LOADING',
  START_EXPORT_TO_EXCEL: 'START_EXPORT_TO_EXCEL',
  START_EXPORT_TO_PDF: 'START_EXPORT_TO_PDF',
  TOGGLE_FILTER_VISIBILITY: 'TOGGLE_FILTER_VISIBILITY',
  CHANGE_PORTFOLIO_DOWNLOAD_FILE_TYPE: 'CHANGE_PORTFOLIO_DOWNLOAD_FILE_TYPE',
  CHANGE_PORTFOLIO_FIT_TO_PAGE_TYPE: 'CHANGE_PORTFOLIO_FIT_TO_PAGE_TYPE'
};
