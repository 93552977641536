export default {
  OPEN_CUSTOM_VIEW_MODAL: 'OPEN_CUSTOM_VIEW_MODAL',
  CLOSE_CUSTOM_VIEW_MODAL: 'CLOSE_CUSTOM_VIEW_MODAL',
  DELETE_FUND_FINDER_CUSTOM_VIEW: 'DELETE_FUND_FINDER_CUSTOM_VIEW',
  EDIT_FUND_FINDER_CUSTOM_VIEW: 'EDIT_FUND_FINDER_CUSTOM_VIEW',
  CHANGE_FUND_FINDER_VIEW: 'CHANGE_FUND_FINDER_VIEW',
  ADD_SAVE_VIEW_DATA: 'ADD_SAVE_VIEW_DATA',
  ADD_EDIT_DELETE_FUND_FINDER_VIEW_DATA: 'ADD_EDIT_DELETE_FUND_FINDER_VIEW_DATA',
  SAVE_FUND_FINDER_CUSTOM_VIEW: 'SAVE_FUND_FINDER_CUSTOM_VIEW',
  VIEWS_UPDATE: 'VIEWS_UPDATE',
  ADD_FUND_FINDER_HEADER: 'ADD_FUND_FINDER_HEADER',
  ADD_FUND_FINDER_DETAILS: 'ADD_FUND_FINDER_DETAILS',
  SORTED_ROW_DATA_UPDATE: 'SORTED_ROW_DATA_UPDATE',
  EXPORT_EXCEL: 'EXPORT_EXCEL',
  SORT_CHANGE: 'SORT_CHANGE',
  FUND_FINDER_COLUMN_WIDTH_CHANGE: 'FUND_FINDER_COLUMN_WIDTH_CHANGE',
  FUND_FINDER_COLUMN_ORDER_CHANGE: 'FUND_FINDER_COLUMN_ORDER_CHANGE',
  FUND_FINDER_COLUMN_REMOVE: 'FUND_FINDER_COLUMN_REMOVE',
  FUND_FINDER_CHANGE_COLUMN_SORT: 'FUND_FINDER_CHANGE_COLUMN_SORT',
  LITERATURE_FETCH: 'LITERATURE_FETCH',
  FETCH_FUND_FACT_CARD_CONFIGURATIONS: 'FETCH_FUND_FACT_CARD_CONFIGURATIONS',
  // Fund Finder additional
  ADD_FUND_FINDER_LOADED_DATA: 'ADD_FUND_FINDER_LOADED_DATA',
  FUND_FINDER_GRID_LOADING: 'FUND_FINDER_GRID_LOADING',
  MODAL_LOADING: 'MODAL_LOADING',
  ADD_FUND_FINDER_DATA: 'ADD_FUND_FINDER_DATA',
  FUND_FINDER_START_EXPORT_TO_EXCEL: 'FUND_FINDER_START_EXPORT_TO_EXCEL',
  UPDATE_FINDER_VIEW_COLUMNS_DATA: 'UPDATE_FINDER_VIEW_COLUMNS_DATA',
  APPLY_FUND_FINDER_FILTER: 'APPLY_FUND_FINDER_FILTER',
  CHANGE_DOWNLOAD_FILE_TYPE: 'CHANGE_DOWNLOAD_FILE_TYPE',
  FUND_FINDER_START_EXPORT_TO_PDF: 'FUND_FINDER_START_EXPORT_TO_PDF',
  CHANGE_FIT_TO_PAGE_TYPE: 'CHANGE_FIT_TO_PAGE_TYPE'
};
