import actionTypes from '../../../actions/page/fundFinder/actionTypes';
import {replaceActiveView, updateActiveView} from '../../../helpers/gridFund';

export function fundFinderPageDataReducer(state = {}, action) {
  const { type, data = {} } = action;
  switch (type) {

    case actionTypes.ADD_FUND_FINDER_LOADED_DATA: {
      const {columnsMetadata, managers, fundCurrencies, esgFund, fundInvestmentTypes} = data;
      return { ...state, columnsMetadata, managers, fundCurrencies, esgFund, fundInvestmentTypes};
    }

    default:
      return state;
  }
}

export function fundFinderPageContextReducer(state = {}, action) {
  const { type, data = {} } = action;
  switch (type) {

    case actionTypes.DELETE_FUND_FINDER_CUSTOM_VIEW:
    case actionTypes.EDIT_FUND_FINDER_CUSTOM_VIEW: {
      return { ...state, isModalLoading: true, groupBy: 'none' };
    }

    case actionTypes.OPEN_CUSTOM_VIEW_MODAL:
    case actionTypes.APPLY_FUND_FINDER_FILTER:
    case actionTypes.CLOSE_CUSTOM_VIEW_MODAL: {
      return { ...state, ...data };
    }

    case actionTypes.CHANGE_FUND_FINDER_VIEW:
      return { ...state, ...updateActiveView(state.views, data.id)};

    case actionTypes.ADD_SAVE_VIEW_DATA: {
      const { views, isOpenCustomViewModal, isModalLoading } = data;
      return { ...state, views, isOpenCustomViewModal, isModalLoading };
    }

    case actionTypes.ADD_EDIT_DELETE_FUND_FINDER_VIEW_DATA: {
      const { views, isOpenCustomViewModal, isModalLoading, groupBy, selectedViewId } = data;
      return { ...state, views, isOpenCustomViewModal, isModalLoading, groupBy, selectedViewId };
    }

    case actionTypes.UPDATE_FINDER_VIEW_COLUMNS_DATA: {
      const { views,  groupBy } = data;
      return { ...state, views, groupBy };
    }


    case actionTypes.MODAL_LOADING: {
      return { ...state, isModalLoading: data };
    }

    case actionTypes.FUND_FINDER_START_EXPORT_TO_EXCEL: {
      return { ...state, fundFinderExportExcel: data };
    }

    case actionTypes.ADD_FUND_FINDER_DATA: {
      const {fundFinderData, loading } = data;
      return { ...state, fundFinderData, loading };
    }

    case actionTypes.FUND_FINDER_GRID_LOADING: {
      return {  ...state, loading: data };
    }


    case actionTypes.FUND_FINDER_COLUMN_REMOVE:{
      return { ...state, groupBy: 'none'};
    }

    case actionTypes.FUND_FINDER_COLUMN_WIDTH_CHANGE:
    case actionTypes.FUND_FINDER_COLUMN_ORDER_CHANGE: {
      return { ...state, views: replaceActiveView(state.views, data)};
    }

    case actionTypes.FUND_FINDER_CHANGE_COLUMN_SORT: {
      const views = replaceActiveView(state.views, data);
      return { ...state, views };
    }

    case actionTypes.SORT_CHANGE: {
      return {...state, groupBy: 'none'};
    }

    case actionTypes.ADD_FUND_FINDER_LOADED_DATA: {
      const { views, isOpenCustomViewModal, loading} = data;
      return { ...state, views, isOpenCustomViewModal, loading };
    }
    case actionTypes.CHANGE_DOWNLOAD_FILE_TYPE: {
      return { ...state, fundFinderFileDownloadType: data };
    }
    case actionTypes.CHANGE_FIT_TO_PAGE_TYPE: {
      return { ...state, fundFinderFitToPageType: data };
    }

    default:
      return state;
  }
}

