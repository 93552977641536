export default {
  REPORTS_ADD_LOADED_DATA: 'REPORTS_ADD_LOADED_DATA',
  REPORTS_GRID_LOADING: 'REPORTS_GRID_LOADING',
  ADD_REPORT_HEADER: 'ADD_REPORT_HEADER',
  ADD_REPORT_DETAILS: 'ADD_REPORT_DETAILS',
  OPEN_CUSTOM_VIEW_MODAL_REPORT: 'OPEN_CUSTOM_VIEW_MODAL_REPORT',
  CLOSE_CUSTOM_VIEW_MODAL_REPORT: 'CLOSE_CUSTOM_VIEW_MODAL_REPORT',
  SAVE_CUSTOM_VIEW_REPORT: 'SAVE_CUSTOM_VIEW_REPORT',
  DELETE_CUSTOM_VIEW_REPORT: 'DELETE_REPORT_CUSTOM_VIEW',
  EDIT_CUSTOM_VIEW_REPORT: 'EDIT_CUSTOM_VIEW_REPORT',
  APPLY_FILTER_REPORT: 'APPLY_FILTER_REPORT',
  CHANGE_GROUP_REPORT: 'CHANGE_GROUP_REPORT',
  CHANGE_VIEW_REPORT: 'CHANGE_VIEW_REPORT',
  ACTIVATE_VIEW_REPORT: 'ACTIVATE_VIEW_REPORT',
  CHANGE_DATE_REPORT: 'CHANGE_DATE_REPORT',
  CHANGE_COLUMN_SORT_REPORT: 'CHANGE_COLUMN_SORT_REPORT',
  CHANGE_COLUMN_WIDTH_REPORT: 'CHANGE_COLUMN_WIDTH_REPORT',
  CHANGE_COLUMN_ORDER_REPORT: 'CHANGE_COLUMN_ORDER_REPORT',
  REMOVE_COLUMN_REPORT: 'REMOVE_COLUMN_REPORT',
  ADD_REPORT_LOADED_DATA: 'ADD_REPORT_LOADED_DATA',
  ADD_REPORT_DATA: 'ADD_REPORT_DATA',
  ADD_GROUP_CHANGED_DATA_REPORT: 'ADD_GROUP_CHANGED_DATA_REPORT',
  ADD_SAVE_VIEW_DATA_REPORT: 'ADD_SAVE_VIEW_DATA_REPORT',
  ADD_EDIT_DELETE_VIEW_DATA_REPORT: 'ADD_EDIT_DELETE_VIEW_DATA_REPORT',
  GRID_LOADING_REPORT: 'GRID_LOADING_REPORT',
  MODAL_LOADING_REPORT: 'MODAL_LOADING_REPORT',
  START_EXPORT_TO_EXCEL_REPORT: 'START_EXPORT_TO_EXCEL_REPORT',
  START_EXPORT_TO_PDF_REPORT: 'START_EXPORT_TO_PDF_REPORT',
  TOGGLE_FILTER_VISIBILITY_REPORT: 'TOGGLE_FILTER_VISIBILITY_REPORT',
  CHANGE_FILE_TYPE: 'CHANGE_FILE_TYPE',
  CHANGE_FIT_TO_PAGE_TYPE: 'CHANGE_FIT_TO_PAGE_TYPE',
  REPORT_GRID_LOADING: 'REPORT_GRID_LOADING',
  CHANGE_ADDITIONAL_FILTERS: 'CHANGE_ADDITIONAL_FILTERS',
  CHANGE_ACCOUNTS_FILTERS: 'CHANGE_ACCOUNTS_FILTERS',
  CHANGE_FUNDS_FILTERS: 'CHANGE_FUNDS_FILTERS',
  CHANGE_FAB_FILTERS: 'CHANGE_FAB_FILTERS',
  REPORT_DATE_RANGE_CHANGE: 'REPORT_DATE_RANGE_CHANGE',
  LOAD_REPORT_GRID_DATA: 'LOAD_REPORT_GRID_DATA',
  SAVE_CUSTOM_REPORT: 'SAVE_CUSTOM_REPORT',
  SAVE_CUSTOM_REPORT_PENDING: 'SAVE_CUSTOM_REPORT_PENDING',
  LOAD_SAVED_REPORT: 'LOAD_SAVED_REPORT',
  DELETE_CUSTOM_REPORT: 'DELETE_CUSTOM_REPORT',
  SET_REPORT_DATA_TO_DELETE: 'SET_REPORT_DATA_TO_DELETE',
  CLEAR_REPORT_SERVICE_ERROR: 'CLEAR_REPORT_SERVICE_ERROR',
  HIDE_REPORT_ROLLBACK_SNACKBAR_MSG: 'HIDE_REPORT_ROLLBACK_SNACKBAR_MSG'
};
