export const gridActionTypes = {
  ADD_GRID_DATA: 'ADD_GRID_DATA',
  GRID_CLEAN_DATA: 'GRID_CLEAN_DATA',
  GRID_COLUMN_SORT_CHANGE:'GRID_COLUMN_SORT_CHANGE',
  GRID_COLUMN_WIDTH_CHANGE:'GRID_COLUMN_WIDTH_CHANGE',
  GRID_COLUMN_ORDER_CHANGE:'GRID_COLUMN_ORDER_CHANGE',
  GRID_COLUMN_REMOVE_CHANGE:'GRID_COLUMN_REMOVE_CHANGE',
  GRID_FILTER_CHNAGE: 'GRID_FILTER_CHNAGE',
  GRID_GROUP_CHNAGE: 'GRID_GROUP_CHNAGE',
  GRID_VIEW_CHNAGE: 'GRID_VIEW_CHNAGE',
  GRID_TOGGLE_FILTER_VISIBILITY: 'GRID_TOGGLE_FILTER_VISIBILITY',
  GRID_START_EXPORT_TO_EXCEL: 'GRID_START_EXPORT_TO_EXCEL',
  GRID_TOGGLE_GROUP_COLLAPSE: 'GRID_TOGGLE_GROUP_COLLAPSE',
  GRID_FILTER_WITH_ERROR: 'GRID_FILTER_WITH_ERROR',
  GRID_START_EXPORT_TO_PDF: 'GRID_START_EXPORT_TO_PDF',
  CHANGE_DOWNLOAD_FILE_TYPE: 'CHANGE_DOWNLOAD_FILE_TYPE',
  CHANGE_FIT_TO_PAGE_TYPE: 'CHANGE_FIT_TO_PAGE_TYPE',
  OPEN_CUSTOM_VIEW_MODAL: 'OPEN_CUSTOM_VIEW_MODAL',
  CLOSE_CUSTOM_VIEW_MODAL: 'CLOSE_CUSTOM_VIEW_MODAL',
  SAVE_CUSTOM_VIEW: 'SAVE_CUSTOM_VIEW',
  EDIT_CUSTOM_VIEW: 'EDIT_CUSTOM_VIEW',
  DELETE_CUSTOM_VIEW: 'DELETE_CUSTOM_VIEW',
  GRID_SORT_DATA: 'GRID_SORT_DATA'
};

export const withId = (action, id) => {
  if(!id){
    return action;
  }
  return `${id}/${action}`;
}

export const gridActionTypesWithId = id => {
  const actions = {};
  Object.keys(gridActionTypes).forEach(action => {
    actions[action] = withId(action, id);
  })
  return actions
}

export const addGridData = id => data => ({
  type: withId(gridActionTypes.ADD_GRID_DATA, id),
  data,
  gridId: id
})

export const gridCleanData = id => data => ({
  type: withId(gridActionTypes.GRID_CLEAN_DATA, id),
  data,
  gridId: id
})

export const gridColumnSortChange = id => data => ({
  type: withId(gridActionTypes.GRID_COLUMN_SORT_CHANGE, id),
  data: data.activeView,
  sortedData: data.sortedData,
  gridId: id
})

export const gridSortData = id => data => ({
  type: withId(gridActionTypes.GRID_SORT_DATA, id),
  data,
  gridId: id
})

export const gridColumnWidthChange = id => data => ({
  type: withId(gridActionTypes.GRID_COLUMN_WIDTH_CHANGE, id),
  data,
  gridId: id
})

export const gridColumnOrderChange = id => data => ({
  type: withId(gridActionTypes.GRID_COLUMN_ORDER_CHANGE, id),
  data,
  gridId: id
})

export const gridColumnRemoveChange = id => data => ({
  type: withId(gridActionTypes.GRID_COLUMN_REMOVE_CHANGE, id),
  data,
  gridId: id
})

export const gridFilterChange = id => data => ({
  type: withId(gridActionTypes.GRID_FILTER_CHNAGE, id),
  data,
  gridId: id
})

export const gridGroupChange = id => data => ({
  type: withId(gridActionTypes.GRID_GROUP_CHNAGE, id),
  data,
  gridId: id
})

export const gridViewChange = id => data => ({
  type: withId(gridActionTypes.GRID_VIEW_CHNAGE, id),
  data,
  gridId: id
})

export const gridToggleFilterVisisbility = id => data => ({
  type: withId(gridActionTypes.GRID_TOGGLE_FILTER_VISIBILITY, id),
  data,
  gridId: id
})

export const gridStartExportToExcel = id => data => ({
  type: withId(gridActionTypes.GRID_START_EXPORT_TO_EXCEL, id),
  data,
  gridId: id
});

export const gridStartExportToPdf = id => data => ({
  type: withId(gridActionTypes.GRID_START_EXPORT_TO_PDF, id),
  data,
  gridId: id
})

export const gridToggleGroupCollapse = id => data => ({
  type: withId(gridActionTypes.GRID_TOGGLE_GROUP_COLLAPSE, id),
  data,
  gridId: id
})

export const gridFilterChangeWithError = id => data => ({
  type: withId(gridActionTypes.GRID_FILTER_WITH_ERROR, id),
  data,
  gridId: id
});

export const gridChangeFileType = id => data => ({
  type: withId(gridActionTypes.CHANGE_DOWNLOAD_FILE_TYPE, id),
  data,
  gridId: id
});

export const gridChangeFitToPageType = id => data => ({
  type: withId(gridActionTypes.CHANGE_FIT_TO_PAGE_TYPE, id),
  data,
  gridId: id
});

export const gridOpenCustomViewModal = id => data => ({
  type: withId(gridActionTypes.OPEN_CUSTOM_VIEW_MODAL, id),
  data,
  gridId: id
});

export const gridCloseCustomViewModal = id => data => ({
  type: withId(gridActionTypes.CLOSE_CUSTOM_VIEW_MODAL, id),
  data,
  gridId: id
});

export const gridSaveCustomView = id => data => ({
  type: withId(gridActionTypes.SAVE_CUSTOM_VIEW, id),
  data,
  gridId: id
});

export const gridEditCustomView = id => data => ({
  type: withId(gridActionTypes.EDIT_CUSTOM_VIEW, id),
  data,
  gridId: id
});

export const gridDeleteCustomView = id => data => ({
  type: withId(gridActionTypes.DELETE_CUSTOM_VIEW, id),
  data,
  gridId: id
});

export const actionsCreators = id => {
  if(!id) console.warn('Grid Module ID is not defined in actionsCreators function');
  return {
    gridColumnSortChange: gridColumnSortChange(id),
    gridColumnWidthChange: gridColumnWidthChange(id),
    gridColumnOrderChange: gridColumnOrderChange(id),
    gridColumnRemoveChange: gridColumnRemoveChange(id),
    gridFilterChange: gridFilterChange(id),
    gridGroupChange: gridGroupChange(id),
    gridViewChange: gridViewChange(id),
    gridToggleFilterVisisbility: gridToggleFilterVisisbility(id),
    gridStartExportToExcel: gridStartExportToExcel(id),
    gridStartExportToPdf: gridStartExportToPdf(id),
    gridToggleGroupCollapse: gridToggleGroupCollapse(id),
    addGridData: addGridData(id),
    gridCleanData: gridCleanData(id),
    gridFilterChangeWithError: gridFilterChangeWithError(id),
    gridChangeFileType: gridChangeFileType(id),
    gridChangeFitToPageType: gridChangeFitToPageType(id),
    gridOpenCustomViewModal: gridOpenCustomViewModal(id),
    gridCloseCustomViewModal: gridCloseCustomViewModal(id),
    gridSaveCustomView: gridSaveCustomView(id),
    gridEditCustomView: gridEditCustomView(id),
    gridDeleteCustomView: gridDeleteCustomView(id),
    gridSortData: gridSortData(id)
  }
}
